import React from 'react';
import './ProfileSummary.scss';
import {
  CloseIcon,
  EditIcon,
  OfferIcon,
  TackHoverIcon,
  TackPressedIcon,
  TrashIcon,
  VeeeIcon
} from '../../../../app/components/svg';
import QuillBS from '../../../../app/components/quillBS/QuillBS.component';
import DefaultImageComponent from '../../../../app/components/DefaultImage.component';
import utilsService from '../../../../services/utils.service';

function SummaryFooter({ creatorName, creatorImage, createdAt, updatedAt }) {
  const time = updatedAt ? updatedAt : createdAt
  return (
    <div className="footer-container">
      <DefaultImageComponent
        url={creatorImage}
        alt={creatorName}
        profileName={creatorName}
      />

      <span className="footer-text">
        {creatorName ? `${utilsService.capitalizeString(creatorName)}, ${time}` : time}
      </span>
    </div>
  );
}

export function ProfileSummary({ text, creatorName, createdAt, updatedAt, creatorImage, isPrimary, onEditClick, onDeleteClick, onPrimaryClick }) {
  return <div className='note-item'>
    <div className="icons-container-1">
      <div onClick={() => onPrimaryClick(isPrimary)} >
        {isPrimary ? <TackPressedIcon/> : <TackHoverIcon/>}
      </div>

      <div onClick={onEditClick}>
        <EditIcon/>
      </div>
      <div onClick={onDeleteClick}>
        <TrashIcon/>
      </div>
    </div>

    <div className="text-container">
      <QuillBS
        html={text}
        isInEditMode={false}
      />
    </div>

    <SummaryFooter
      creatorName={creatorName}
      creatorImage={creatorImage}
      createdAt={createdAt}
      updatedAt={updatedAt}
    />
  </div>
}


export function ProfileSummaryEditor({ text, creatorName, createdAt, updatedAt, creatorImage, onSaveClick, onCloseClick, onChange }) {
  return (
    <div className='note-item'>
      <div className="icons-container-2">
        <div
          className="close-icon-container"
          onClick={onCloseClick}
        >
          <CloseIcon/>
        </div>

        <div
          className={
            'vee-icon-container '
            + (text && (text.length === 0 || text === '<p><br></p>')
              ? 'prevent-closing'
              : '')
          }
          onClick={onSaveClick}
        >
          <VeeeIcon/>
        </div>
      </div>

      <div className="text-container">
        <QuillBS
          html={text}
          isInEditMode
          onChange={onChange}
        />
      </div>

      <SummaryFooter
        creatorName={creatorName}
        creatorImage={creatorImage}
        createdAt={createdAt}
        updatedAt={updatedAt}
      />
    </div>
  );
}

export function SummaryManager({ children, onAddClick }) {
  return <div className='profile-summary-container'>
    <div className='profile-summary-action-group'>
      <div
        onClick={onAddClick}
        className="button add-note-button"
      >
        <span/>
        <OfferIcon/>
        <span className="text">New</span>
      </div>
    </div>

    {children}
  </div>;
}
